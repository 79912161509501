import React from 'react'

export default function MiniSection() {
  return (
    <div className='mini--section'>
        <h2>Lets Launch your website in 7 days or less</h2> 
        <button>SEND DETAILS</button>
    </div>
  )
}
