export const workData = [
    {
        "position": "01",
        "title": "Requirement/Info",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, consectetur."
    },
    {
        "position": "02",
        "title": "Project Planning",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, consectetur."
    },
    {
        "position": "03",
        "title": "Prototyping & Design",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, consectetur."
    },
    {
        "position": "04",
        "title": "Coding/Development",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, consectetur."
    },
    {
        "position": "05",
        "title": "Deployment",
        "desc": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, consectetur."
    }
]